.navbar-collapse.collapsing {
    transition: none;
}

.navbar-toggler {
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.5);
}
.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.8)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}
.navbar-toggler-icon:focus {
    border-color: #fff;
}

.homenav-button {
    color: #fff;
    text-decoration: none;
    border: 2px solid white;
    border-radius: 50px;
    padding: 0.42rem 1.5rem !important;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    margin: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
}
.homenav-button.connect {
    color: #000;
    background: #fff;
}
.homenav-button.connect .dot {
    height: 0.8rem;
    width: 0.8rem;
    background-color: #333;
    border-radius: 50%;
    display: inline-block;
    margin: 0.04rem 0.2rem -0.04rem 0;
}

@media screen and (max-width: 576px) {
    .homenav-button.connect {
        font-size: 0.8rem;
        margin: 0 0.5rem 0 0;
        padding: 0.42rem 1rem !important;
    }

}

.navbar-collapse.collapse.show .homenav-button {
    background: rgba(0, 0, 0, 0.75);
    margin: 1rem 0 0;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.navbar-brand:hover,
.homenav-button:hover {
    opacity: 0.5;
    color: #fff;
}

.homenav-button.connect:hover {
    color: #000;
}

.socials {
    font-size: 1.5rem;
    margin: 0 0 7px 0.5rem;
}
.social {
    color: #fff;
}
.social path {
    stroke: #0f1954;
    stroke-width: 1;
}
.social.opensea {
    margin-left: 4px;
}
.social:hover {
    opacity: 0.5;
    color: #fff;
}

.navbar-collapse.collapse.show .socials {
    margin: 2rem auto;
    display: block;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.75);
    text-align: center;
}
.navbar-collapse.collapse.show .socials .nav-link {
    margin: 0 1rem;
    display: inline-block;
}

.nav-link.active {
    color: #fff !important;
}