html, body {
  height: 100%;
  margin: 0;
}

.teamContainer {
  background-image: url("../assets/images/background-noise.png");
  min-height: 100vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.team .profile {
  width: 100%;
  margin-bottom: -2.3rem;
}

.team .person {
  padding: 0 1rem;
}

.team .name {
  font-size: 1.3rem;
  text-transform: uppercase;
}

.team .title {
  background: #fff;
  color: #000;
  display: inline-block;
  padding: 0 0.75rem 0 0.5rem;
  text-transform: uppercase;
  font-style: italic;
  margin: 0 0 1.5rem;
}

.team .links {
  margin: -0.5rem 0 1rem;
  font-size: 1.3rem;
  padding: 0 1rem;
}
.team .links a {
  margin-right: 0.5rem;
  color: #fff;
}

.team .links a:hover {
  opacity: 0.5;
}

.team div[class^="col-"] {
  position: relative;
  margin-bottom: 3rem;
}
.team div[class^="col-"]:after {
  content: ' ';
  position: absolute;
  right: 10px;
  top: -25px;
  width: 47px;
  height: 72px;
  background-image: url("../assets/images/team-claw.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  
}