html, body {
  height: 100%;
  margin: 0;
}

.mintContainer {
  background-image: url("../assets/images/background-mint.png");
  min-height: 100vh; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mintWrapper {
  background-image: url("../assets/images/background-static.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  border-radius: 50px;
  width: 80%;
  margin: 1rem auto;
}

.mintPanther {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.5);
  background-image: url("../assets/images/panther.png");
  background-blend-mode: darken, luminosity;
  background-position: 20% 100%;
  background-repeat: no-repeat;
  background-size: 25%;
}

.mint {
  padding: 2rem;
}

.mint .price {
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: bold;
}

.mint select {
  margin: 3rem 0 1rem;
  background: #333;
  color: #fff;
  font-size: 2rem;
  padding: 0.2rem 2rem;
  border: none;
  border-radius: 0.5rem;
}

.mint .stats {
  font-weight: bold;
  text-transform: uppercase;
}
.mint .stats .minted {
  margin: 1rem 0;
}
.mint .stats .whitelist {
  margin: 3rem 0 1rem;
}

.mint-button {
  display: inline-block;
  background: #fff;
  color: #000;
  text-decoration: none;
  border: none;
  border-radius: 50px;
  padding: 5px 5rem;
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  min-width: 10rem;
  text-align: center;
  margin: 1.5rem 0;
}

.mint-button:hover {
  opacity: 0.5;
  color: #000;
}

@media screen and (max-width: 1024px) {

  .mintPanther {
    background-size: 60%;
  }

}