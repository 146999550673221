.footerLinks {
    padding: 1rem 0 2rem;
}

.footerLinks a {
    font-size: 0.9rem;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    margin: 0 2rem;
}

.footerLinks a:hover {
    text-decoration: none;
}

@media screen and (max-width: 576px) {
    .footerLinks {
        text-align: center !important;
    }
    .footerLinks a {
        font-size: 0.7rem;
        margin: 0 1rem;
    }
}