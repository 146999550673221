.contentMain, .background {
    /* Set rules to fill background */
    min-height: 100%;
    min-width: 1024px;
      
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
      
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
  }

  .preview {
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 35%;
    max-width: 350px;
  }

  .home-navbar-social {
    position: fixed;
    bottom: 1rem;
    width: 100%;
  }
  .home-navbar-social .container {
    justify-content: flex-start;
    gap: 0.75rem;
  }
  .home-navbar-social .social {
    font-size: 1.5rem;
  }
  
  @media screen and (max-width: 1024px) { /* Specific to this particular image */
    .contentMain, .background {
      left: 50%;
      margin-left: -512px;   
    }
    .preview {
      left: 35%;
      width: 25%;/* 50% */
    }
}